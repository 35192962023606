import { NextPage } from 'next';
import { useReplace, useTranslator } from '@hooks';
import { ErrorLayout } from '~/components/layouts';
import { Error, ErrorTemplate } from '~/components/templates';
import { HttpStatusCodeEnum } from '~/utils/enums/httpStatusCode';

// NOTE: cannot use getInitialProps and getServerSideProps
// TODO: Check a possibility to retrieve the location for translation of this page
const NotFoundPage: NextPage = () => {
    const { details } = useTranslator().pageError;
    const replace = useReplace();

    const error = {
        statusCode: HttpStatusCodeEnum.NotFound,
        subTitle: String(details.subTitle),
        description: String(details.errorTypes.pageNotFound),
        message: String(details.message)
    } as Error;

    return (
        <ErrorLayout title={replace(details.title, error.statusCode)}>
            <ErrorTemplate error={error} />
        </ErrorLayout>
    );
};

export default NotFoundPage;
